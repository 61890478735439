import React, { useState } from "react";

const CommentForm = ({ handleSubmit, submitLabel, parentId }) => {
  const MAXLENGTH = 2000;
  const initialState = {
    name: "",
    email: "",
    body: "",
    active: false,
    parent: parentId,
  };
  const [state, setState] = useState(initialState);
  const isTextareaDisabled =
    state.name.length === 0 ||
    state.email.length === 0 ||
    state.body.length === 0;

  const onSubmit = (event) => {
    event.preventDefault();
    handleSubmit(state);
    setState(initialState);
  };

  const handleChange = (evt) => {
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  return (
    <React.Fragment>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label className="sr-only" htmlFor="name">
            Name
          </label>
          <input
            id="name"
            name="name"
            className="form-control"
            type="text"
            placeholder="Tên của bạn"
            value={state.name}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label className="sr-only" htmlFor="email">
            Name
          </label>
          <input
            id="email"
            name="email"
            className="form-control"
            type="email"
            placeholder="Email của bạn"
            value={state.email}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <textarea
            id="body"
            name="body"
            className="form-control"
            value={state.body}
            onChange={handleChange}
            placeholder="Ý kiến của bạn"
            rows="5"
            maxLength={MAXLENGTH}
          />
        </div>
        <div className="form-group">
          <input
            type="submit"
            value={submitLabel}
            className="btn btn-round btn-d"
            disabled={isTextareaDisabled}
          />
        </div>
      </form>
    </React.Fragment>
  );
};

export default CommentForm;

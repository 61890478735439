import React from "react";
import Header from "./Header";
import Footer from "./Footer";

export default function MainLayout(props) {
  return (
    <React.Fragment>
      <main>
        {/* <div className="page-loader">
          <div className="loader">Loading...</div>
        </div> */}
        <Header />
        {props.children}
        <Footer />
        <div className="scroll-up">
          <a href="#totop">
            <i className="fa fa-angle-double-up"></i>
          </a>
        </div>
      </main>
    </React.Fragment>
  );
}

import { API } from "./api-service";

export class dataCommonFunction {
  static handleActionBox(item, rows, action, indexes, token) {
    switch (action) {
      case 1:
        indexes &&
          indexes.map((index) => {
            API.updateItem(item, rows[index].id, { active: false }, token)
              .then((resp) => {
                if (resp.active) {
                  rows[index]["active"] = "Yes";
                } else {
                  rows[index]["active"] = "No";
                }
                return rows;
              })
              .catch((error) => console.log(error));
          });
        return rows;

        break;
      case 2:
        indexes &&
          indexes.map((index) => {
            API.updateItem(item, rows[index].id, { active: true }, token)
              .then((resp) => {
                if (resp.active) {
                  rows[index]["active"] = "Yes";
                } else {
                  rows[index]["active"] = "No";
                }
                return rows;
              })
              .catch((error) => console.log(error));
          });
        return rows;
        break;
      default:
        return rows;
        break;
    }
  }

  static createSelectOption(options_db) {
    const options = [];
    options_db &&
      options_db.map((t) => {
        options.push({ value: t.id, label: t.name });
      });

    return options;
  }

  static createOption(item, body, token, list) {
    setTimeout(() => {
      return API.createItem(item, body, token)
        .then((resp) => {
          let option = { value: resp.result.id, label: resp.result.name };
          list = list.push(option);
          return list;
        })
        .catch((error) => console.log(error));
    }, 1000);
  }

  static async getThumpUrl(id) {
    var link = "articlefiles/" + id.toString() + "/";

    let files = await API.getItemsFront(link)
      .then((resp) => {
        return resp.files;
      })
      .catch((error) => console.log(error));

    var url = "";
    if (files.length > 0) {
      url = "url(" + files[0].file + ")";
    } else {
      url =
        "url(https://storage.googleapis.com/tinicorner_test/thumpUrlDefault1.jpeg)";
    }
    return url;
  }

  static async getAudioThumpUrl(id) {
    var link = "articlefiles/" + id.toString() + "/";

    let files = await API.getItemsFront(link)
      .then((resp) => {
        return resp.files;
      })
      .catch((error) => console.log(error));

    var url = "";
    if (files.length > 0) {
      url = files[0].file;
    } else {
      url =
        "https://storage.googleapis.com/tinicorner_test/thumpUrlDefault1.jpeg";
      // url = "assets/images/post-1.jpg";
    }
    return url;
  }

  static async getHottestArticles(num) {
    var link = `get_hottest_articles_by_site_domain/${process.env.REACT_APP_DOMAIN}/${num}/`;

    var list = await API.getItemsFront(link)
      .then(async (resp) => {
        var datas = resp.articles;
        var articlesList = [];
        // var sliderList = [];
        // var sidebarList = [];
        for (let i = 0; i < datas.length; i++) {
          var url = await dataCommonFunction
            .getAudioThumpUrl(datas[i].id)
            .catch(console.error);
          var link = "get_author_by_id/" + datas[i].author.toString() + "/";
          var author = await API.getItemsFront(link).then((resp3) => {
            return resp3.author;
          });
          var article = {
            id: datas[i].id,
            title: datas[i].title,
            author: author,
            slug: datas[i].slug,
            notes: datas[i].notes,
            created_at: datas[i].created_at,
            is_finished: datas[i].is_finished,
            number_of_view: datas[i].number_of_view,
            thumpUrl: url,
          };
          articlesList.push(article);
        }
        return articlesList;
      })
      .catch((error) => console.log(error));
    return list;
  }

  static async getNewestArticles(num) {
    var link = `get_newest_articles_by_site_domain/${process.env.REACT_APP_DOMAIN}/${num}/`;

    var list = await API.getItemsFront(link)
      .then(async (resp) => {
        var datas = resp.articles;
        var articlesList = [];
        // var sliderList = [];
        // var sidebarList = [];
        for (let i = 0; i < datas.length; i++) {
          var url = await dataCommonFunction
            .getAudioThumpUrl(datas[i].id)
            .catch(console.error);
          var link = "get_author_by_id/" + datas[i].author.toString() + "/";
          var author = await API.getItemsFront(link).then((resp3) => {
            return resp3.author;
          });
          var article = {
            id: datas[i].id,
            title: datas[i].title,
            author: author,
            slug: datas[i].slug,
            notes: datas[i].notes,
            created_at: datas[i].created_at,
            is_finished: datas[i].is_finished,
            number_of_view: datas[i].number_of_view,
            thumpUrl: url,
          };
          articlesList.push(article);
        }
        return articlesList;
      })
      .catch((error) => console.log(error));
    return list;
  }
}

import React, { useState, useEffect } from "react";
import { API } from "../common/api-service";
import { dataCommonFunction } from "../common/data-common-function";
import Sidebar from "../layout/Sidebar";
import Article from "./BlogStandardRightSidebar";
import Pagination from "../common/Pagination";
import { useLocation } from "react-router-dom";

export default function SearchResults() {
  const [articles, setArticles] = useState([]);
  const location = useLocation();
  const searching_text = new URLSearchParams(location.search).get("search");
  useEffect(() => {
    if (searching_text) {
      var link = `get_audio_articles_by_searching_text/${searching_text}/${process.env.REACT_APP_DOMAIN}/`;
      API.getItemsFront(link)
        .then(async (resp) => {
          var datas = resp.articles;
          var articlesList = [];
          for (let i = 0; i < datas.length; i++) {
            var url = await dataCommonFunction
              .getAudioThumpUrl(datas[i].id)
              .catch(console.error);
            var link =
              "get_genres_by_article_id/" + datas[i].id.toString() + "/";
            var genres = await API.getItemsFront(link).then((resp2) => {
              return resp2.genres;
            });
            var link = "get_author_by_id/" + datas[i].author.toString() + "/";
            var author = await API.getItemsFront(link).then((resp3) => {
              return resp3.author;
            });
            var article = {
              id: datas[i].id,
              title: datas[i].title,
              author: author,
              genres: genres,
              slug: datas[i].slug,
              notes: datas[i].notes,
              created_at: datas[i].created_at,
              is_finished: datas[i].is_finished,
              number_of_view: datas[i].number_of_view,
              thumpUrl: url,
            };
            articlesList.push(article);
          }
          setArticles(articlesList);
        })
        .catch((error) => console.log(error));
    }
  }, []);
  return (
    <div className="main">
      <section className="module">
        <div className="container">
          <div className="row">
            <div className="col-sm-8">
              {articles.length > 0 ? (
                <>
                  <Pagination
                    data={articles}
                    RenderComponent={Article}
                    title="Articles"
                    pageLimit={4}
                    dataLimit={20}
                  />
                </>
              ) : (
                <h3>Không có bài viết nào</h3>
              )}
            </div>
            <Sidebar />
          </div>
          {/* <ul
              class="works-grid works-grid-gut works-grid-4 works-hover-w"
              id="works-grid"
            >
              <ArticlesList articles={articles} />
            </ul> */}
        </div>
      </section>
    </div>
  );
}

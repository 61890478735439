import React, { useState, useEffect } from "react";
import { API } from "../common/api-service";
import { Link } from "react-router-dom";

export default function GenresList() {
  const [genres, setGenres] = useState([]);

  useEffect(() => {
    var link = `get_genres_by_site_domain/${process.env.REACT_APP_DOMAIN}/`;
    API.getItemsFront(link)
      .then((resp) => {
        setGenres(resp.genres);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <React.Fragment>
      <ul className="icon-list">
        {genres &&
          genres.map((genre, index) => (
            <li key={"genre" + index}>
              <Link
                target="_blank"
                to={{
                  pathname: `/genres/${genre.slug}/`,
                  state: { id: genre.id },
                }}
              >
                {genre.name}
              </Link>
            </li>
          ))}
      </ul>
    </React.Fragment>
  );
}

import React from "react";
import GenresList from "../articles/GenresList";
import LastArticles from "../articles/LastArticles";
import HottestArticles from "../articles/HottestArticles";

export default function Sidebar() {
  return (
    <React.Fragment>
      <div className="col-sm-4 col-md-3 col-md-offset-1 sidebar">
        {/* <div className="widget">
          <form role="form">
            <div className="search-box">
              <input className="form-control" type="text" placeholder="Search..." />
              <button className="search-btn" type="submit">
                <i className="fa fa-search"></i>
              </button>
            </div>
          </form>
        </div> */}
        <div className="widget">
          <h5 className="widget-title font-alt">Thể loại</h5>
          <GenresList />
        </div>
        <div className="widget">
          <h5 className="widget-title font-alt">Truyện hot</h5>
          <HottestArticles quantity={6} />
        </div>
        <div className="widget">
          <h5 className="widget-title font-alt">Truyện mới</h5>
          <LastArticles quantity={6} />
        </div>
        {/* <div className="widget">
          <h5 className="widget-title font-alt">Tag</h5>
          <div className="tags font-serif">
            <a href="#" rel="tag">
              Blog
            </a>
            <a href="#" rel="tag">
              Photo
            </a>
            <a href="#" rel="tag">
              Video
            </a>
            <a href="#" rel="tag">
              Image
            </a>
            <a href="#" rel="tag">
              Minimal
            </a>
            <a href="#" rel="tag">
              Post
            </a>
            <a href="#" rel="tag">
              Theme
            </a>
            <a href="#" rel="tag">
              Ideas
            </a>
            <a href="#" rel="tag">
              Tags
            </a>
            <a href="#" rel="tag">
              Bootstrap
            </a>
            <a href="#" rel="tag">
              Popular
            </a>
            <a href="#" rel="tag">
              English
            </a>
          </div>
        </div> */}
        {/* <div className="widget">
          <h5 className="widget-title font-alt">Text</h5>The languages only differ
          in their grammar, their pronunciation and their most common words.
          Everyone realizes why a new common language would be desirable: one
          could refuse to pay expensive translators.
        </div>
        <div className="widget">
          <h5 className="widget-title font-alt">Recent Comments</h5>
          <ul className="icon-list">
            <li>
              Maria on <a href="#">Designer Desk Essentials</a>
            </li>
            <li>
              John on <a href="#">Realistic Business Card Mockup</a>
            </li>
            <li>
              Andy on <a href="#">Eco bag Mockup</a>
            </li>
            <li>
              Jack on <a href="#">Bottle Mockup</a>
            </li>
            <li>
              Mark on <a href="#">Our trip to the Alps</a>
            </li>
          </ul>
        </div> */}
      </div>
    </React.Fragment>
  );
}

import React, { useState, useEffect } from "react";
// import { API } from "../common/api-service";
import { dataCommonFunction } from "../common/data-common-function";
import Article from "../articles/BlogGridColArticle";
// import Pagination from "../common/Pagination";
import GenreCarousel from "../articles/GenreCarousel";

export default function Homepage() {
  const HOTTEST_ARTICLE_QUANTITY = 12;
  const NEWEST_ARTICLE_QUANTITY = 48;
  const [hottest_list, setHottestList] = useState([]);
  const [newest_list, setNewestList] = useState([]);

  useEffect(() => {
    dataCommonFunction
      .getHottestArticles(HOTTEST_ARTICLE_QUANTITY)
      .then((resp1) => {
        setHottestList(resp1);
      });

    dataCommonFunction
      .getNewestArticles(NEWEST_ARTICLE_QUANTITY)
      .then((resp2) => {
        setNewestList(resp2);
      });
  }, []);

  return (
    <React.Fragment>
      <div className="main">
        <section className="module-custom">
          <div className="container">
            {/* <div className="row multi-columns-row post-columns"> */}
            {/* <ArticlesList articles={articles} /> */}
            {/* </div> */}
            {/* <ul
              class="works-grid works-grid-gut works-grid-4 works-hover-w"
              id="works-grid"
            > */}
            {/* <div className="row multi-columns-row post-columns genre-carousel font-alt"> */}
            <div className="row genre-carousel font-alt">
              <GenreCarousel />
            </div>

            <h4 class="font-alt mb-0">Truyện Hot</h4>
            <hr class="divider-w mt-10 mb-20"></hr>
            <div class="row multi-columns-row">
              {hottest_list && hottest_list.length > 0 ? (
                hottest_list.map((data, idx) => (
                  <Article key={idx} data={data} />
                ))
              ) : (
                // <>
                //   {<Pagination
                //     data={articles}
                //     RenderComponent={Article}
                //     title="Articles"
                //     pageLimit={4}
                //     dataLimit={10}
                //   />}
                //   <Article key={idx} data={d} />
                // </>
                <h3>Không có bài viết nào</h3>
              )}
            </div>
            <h4 class="font-alt mb-0">Truyện Mới</h4>
            <hr class="divider-w mt-10 mb-20"></hr>
            <div class="row multi-columns-row">
              {newest_list && newest_list.length > 0 ? (
                newest_list.map((data, idx) => (
                  <Article key={idx} data={data} />
                ))
              ) : (
                <h3>Không có bài viết nào</h3>
              )}
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}

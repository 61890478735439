import React from "react";
import GenresList from "../articles/GenresList";
import LastArticles from "../articles/LastArticles";
import HottestArticles from "../articles/HottestArticles";

export default function Footer() {
  return (
    <React.Fragment>
      <div className="module-small bg-dark">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <div className="widget">
                <h5 className="widget-title font-alt">Về Gác Audios</h5>
                <p>
                  Website luôn cập nhật audio của những bộ truyện mới thuộc các
                  thể loại đặc sắc như truyện tiên hiệp, truyện kiếm hiệp, hay
                  truyện ngôn tình, ... một cách nhanh nhất.
                </p>
                <div>
                  <p>
                    <strong>Ủng hộ cho Gác Audios qua các kênh sau:</strong>
                  </p>
                  <p>
                    <a
                      href="https://me.momo.vn/saubeo"
                      target="_blank"
                      // style={{ width: "30px", margin: "0 10px 0 10px" }}
                    >
                      <img
                        src="/assets/images/donate/Logo-MoMo-Square.png"
                        style={{ width: "50px", margin: "0 10px 0 60px" }}
                      />
                    </a>
                    <a
                      href="https://www.paypal.com/paypalme/yennguyenvn"
                      target="_blank"
                      // style={{ width: "30px", margin: "0 10px 0 10px" }}
                    >
                      <img
                        src="/assets/images/donate/Paypal_2014_logo.png"
                        style={{ width: "50px", margin: "0 10px 0 10px" }}
                      />
                    </a>
                    {/* <a
                      href="https://www.buymeacoffee.com/samnguyen"
                      target="_blank"
                      // style={{ width: "30px", margin: "0 10px 0 10px" }}
                    >
                      <img
                        src="/assets/images/donate/buy-me-a-coffee.png"
                        style={{ width: "50px", margin: "0 10px 0 10px" }}
                      />
                    </a> */}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="widget">
                <h5 className="widget-title font-alt">Truyện mới</h5>
                <LastArticles quantity={3} />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="widget">
                <h5 className="widget-title font-alt">Thể loại</h5>
                <GenresList />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="widget">
                <h5 className="widget-title font-alt">Truyện hot</h5>
                <HottestArticles quantity={3} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <hr className="divider-d" />
      <footer className="footer bg-dark">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <p className="copyright font-alt">
                &copy; 2017&nbsp;<a href="index.html">TitaN</a>, All Rights
                Reserved
              </p>
            </div>
            <div className="col-sm-6">
              <div className="footer-social-links">
                <a href="#">
                  <i className="fa fa-facebook"></i>
                </a>
                <a href="#">
                  <i className="fa fa-twitter"></i>
                </a>
                <a href="#">
                  <i className="fa fa-dribbble"></i>
                </a>
                <a href="#">
                  <i className="fa fa-skype"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer> */}
    </React.Fragment>
  );
}

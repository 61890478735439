import React, { useState, useEffect } from "react";
// import ReactHtmlParser from "react-html-parser";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { API } from "../common/api-service";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import * as moment from "moment";

export default function BlogGridColArticle(props) {
  const [backendComments, setBackendComments] = useState([]);
  const shareUrl = window.location.href + "articles/" + props.data.slug;

  useEffect(() => {
    API.getItemsFront(`get_comments_by_article_id/${props.data.id}/`)
      .then((resp) => {
        setBackendComments(resp.comments);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <React.Fragment>
      {/* <div className="col-sm-6 col-md-3 col-lg-3"> */}
      <div className="col-lg-2 col-md-3 col-sm-4 col-6">
        <div className="post">
          <div className="post-thumbnail promotion-box">
            {/* <div class="ribbon-wrapper">
              <div class="ribbon">FULL</div>
            </div> */}
            {props.data.is_finished ? (
              <div className="promotion-tag-box">
                <div className="promotion-tag-text">FULL</div>
              </div>
            ) : (
              ""
            )}
            <Link
              // target="_blank"
              to={{
                pathname: `/articles/${props.data.slug}/`,
                // state: { id: props.data.id },
              }}
              // style={{
              //   backgroundImage: props.data.thumpUrl,
              // }}
            >
              <span>
                {/* <i className="icon-link"></i> */}
                <img src={props.data.thumpUrl} alt="Blog Featured Image" />
              </span>
            </Link>
          </div>
          <div className="post-header font-alt">
            {/* <h2 className="post-title"> */}
            <h2 className="post-title-list">
              <Link
                // target="_blank"
                to={{
                  pathname: `/articles/${props.data.slug}/`,
                  // state: { id: props.data.id },
                }}
              >
                {props.data.title}
              </Link>
            </h2>
            <div className="post-meta">
              {/* {new Intl.DateTimeFormat("en-US", {
                month: "long",
                day: "2-digit",
                year: "numeric",
              }).format(new Date(props.data.created_at))} */}
              {/* {moment(new Date(props.data.created_at)).format("DD-MM-YYYY")} */}
              {props.data.is_finished ? "Hoàn thành" : "Đang ra"}
            </div>
          </div>
          <div className="post-entry">
            <Link
              // target="_blank"
              to={{
                pathname: `/authors/${props.data.author.slug}/`,
              }}
            >
              {props.data.author.name}
            </Link>
            <div className="post-more">
              <p style={{margin: 0}}>{props.data.notes ? parse(props.data.notes) : ""}</p>
              <p style={{ fontSize: 11 }}>
                <FontAwesomeIcon icon={faEye} /> {props.data.number_of_view}
              </p>
            </div>
          </div>
          {/* <div className="post-more">
            <Link
              // target="_blank"
              to={{
                pathname: `/articles/${props.data.slug}/`,
                //   state: { id: props.data.id },
              }}
              className="more-link"
            >
              Đọc truyện
            </Link>
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
}

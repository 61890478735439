import React, { useState, useEffect } from "react";
import CommentForm from "./CommentForm";
import SingleComment from "./SingleComment";
import ReplyComment from "./ReplyComment";
import { API } from "../common/api-service";

const Comments = ({ commentsUrl, currentUserId, item_id }) => {
  const [commentedMsg, setCommentedMsg] = useState("");
  const [backendComments, setBackendComments] = useState([]);

  const addComment = (data, parentId) => {
    data["article"] = item_id;
    API.createItemFront("guest_comments", data)
      .then((resp) => {
        console.log("resp", resp);
        setCommentedMsg(
          "Your comment has been added and will be previewed soon."
        );
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (item_id) {
      API.getItemsFront(`get_comments_by_article_id/${item_id}/`)
        .then((resp) => {
          setBackendComments(resp.comments);
        })
        .catch((error) => console.log(error));
    }
  }, [item_id]);

  return (
    <React.Fragment>
      <div className="comments">
        {backendComments.length > 1 ? (
          <h4 className="comment-title font-alt">
            Có {backendComments.length} Comments
          </h4>
        ) : (
          <h4 className="comment-title font-alt">
            Có {backendComments.length} Comment
          </h4>
        )}

        {backendComments &&
          backendComments.map(
            (rootComment) =>
              !rootComment.parent && (
                <React.Fragment key={rootComment.id}>
                  <div className="comment clearfix">
                    <SingleComment
                      comment={rootComment}
                      parentId={rootComment.id}
                      handleReplySubmit={addComment}
                      // replies={getReplies(rootComment.id)}
                      // activeComment={activeComment}
                      // setActiveComment={setActiveComment}
                      // addComment={addComment}
                      // deleteComment={deleteComment}
                      // updateComment={updateComment}
                      // currentUserId={currentUserId}
                    />
                    <ReplyComment
                      CommentLists={backendComments}
                      articleId={item_id}
                      parentCommentId={rootComment.id}
                      handleReplySubmit={addComment}
                    />
                  </div>
                </React.Fragment>
              )
          )}
      </div>
      <h4 className="comment-form-title">{commentedMsg}</h4>
      <div className="comment-form">
        <h4 className="comment-form-title font-alt">Mời bạn cho ý kiến</h4>
        <CommentForm
          submitLabel="Comment"
          handleSubmit={addComment}
          item_id={item_id}
        />
      </div>
    </React.Fragment>
  );
};

export default Comments;

import React, { useState } from "react";
import CommentForm from "./CommentForm";
import moment from "moment";

const SingleComment = ({ comment, handleReplySubmit, parentId = null }) => {
  const [isReplying, setIsReplying] = useState(false);
  const onOpenReplyBox = () => {
    setIsReplying(!isReplying);
  };

  return (
    <React.Fragment>
      <div
        className="comment-avatar"
        // style={{
        //   backgroundImage:
        //     "url(https://storage.googleapis.com/tinicorner_test/comment.jpg)",
        // }}
      >
        <img
          src="https://storage.googleapis.com/tinicorner/gacaudios_comment_avatar.png"
          alt="avatar"
        />
      </div>
      <div
        className="comment-content clearfix"
        style={{ marginBottom: 0 }}
      >
        <div className="comment-author font-alt">{comment.name}</div>
        <div className="comment-body" style={{ marginBottom: 20 }}>
          {comment.body}
        </div>
        <div className="comment-meta font-alt">
          {moment.utc(comment.created).local().startOf("seconds").fromNow()} -{" "}
          <a href={void 0} onClick={onOpenReplyBox}>
            Trả lời
          </a>
        </div>
        <div style={{ marginTop: 20 }}>
          {isReplying ? (
            <CommentForm
              submitLabel="Trả lời"
              parentId={parentId}
              handleSubmit={handleReplySubmit}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SingleComment;

import React, { useState } from "react";
import SearchBox from "../common/SearchBox";

export default function Header() {
  const [isOpened, setIsOpened] = useState(false);

  const toggle = () => {
    setIsOpened((wasOpened) => !wasOpened);
  };

  return (
    <React.Fragment>
      <nav className="navbar navbar-custom navbar-fixed-top" role="navigation">
        <div className="container">
          <div className="navbar-header">
            <a className="navbar-brand" href="/">
              Gác Audios
            </a>
            <button
              className="navbar-toggle"
              type="button"
              data-toggle="collapse"
              data-target="#custom-collapse"
              onClick={toggle}
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
          </div>
          {isOpened && (
            <div className="navbar-header">
              <div className="search-box-mobile">
                <SearchBox />
              </div>
            </div>
          )}
          <div className="collapse navbar-collapse" id="custom-collapse">
            <div className="col-sm-4 col-md-3 col-md-offset-1 search-area">
              <SearchBox />
            </div>
            {/* <ul className="nav navbar-nav navbar-right">
              <li>
                <a href="/" data-toggle="dropdown">
                  Trang chủ
                </a>
              </li>
              <li className="dropdown">
                <a className="dropdown-toggle" href="#" data-toggle="dropdown">
                  Thể loại
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a href="index_mp_fullscreen_video_background.html">
                      Default
                    </a>
                  </li>
                  <li>
                    <a href="index_op_fullscreen_gradient_overlay.html">
                      One Page
                    </a>
                  </li>
                  <li>
                    <a href="index_agency.html">Agency</a>
                  </li>
                  <li>
                    <a href="index_portfolio.html">Portfolio</a>
                  </li>
                  <li>
                    <a href="index_restaurant.html">Restaurant</a>
                  </li>
                  <li>
                    <a href="index_finance.html">Finance</a>
                  </li>
                  <li>
                    <a href="index_landing.html">Landing Page</a>
                  </li>
                  <li>
                    <a href="index_photography.html">Photography</a>
                  </li>
                  <li>
                    <a href="index_shop.html">Shop</a>
                  </li>
                </ul>
              </li>
            </ul> */}
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
}

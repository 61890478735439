import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { API } from "../common/api-service";
import { Link } from "react-router-dom";
import {
    CustomLeftArrow,
    CustomRightArrow,
  } from "../common/CustomArrows";

export default function GenreCarousel() {
  const [genres, setGenres] = useState([]);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 9,
      slidesToSlide: 9, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 6,
      slidesToSlide: 6, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
  };
  useEffect(() => {
    var link = `get_genres_by_site_domain/${process.env.REACT_APP_DOMAIN}/`;
    API.getItemsFront(link)
      .then((resp) => {
        setGenres(resp.genres);
      })
      .catch((error) => console.log(error));
  }, []);
  return (
    <React.Fragment>
      <Carousel
        responsive={responsive}
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
        containerClass="carousel-container"
        itemClass="item-container"
        swipeable={true}
        draggable={true}
        infinite={false}
        partialVisible={false}
        // keyBoardControl={true}
        // customTransition="all .5"
        // transitionDuration={500}
        // removeArrowOnDeviceType={["tablet", "mobile"]}
      >
        {genres &&
          genres.map((genre, index) => (
            <Link
              key={"genre" + index}
              target="_blank"
              to={{
                pathname: `/genres/${genre.slug}/`,
                state: { id: genre.id },
              }}
            >
              {genre.name}
            </Link>
          ))}
      </Carousel>
    </React.Fragment>
  );
}

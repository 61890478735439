import React, { useState, useEffect } from "react";
import { API } from "../common/api-service";
import ReactHtmlParser from "react-html-parser";
// import parse from "html-react-parser";
import { dataCommonFunction } from "../common/data-common-function";
import { Link, useParams } from "react-router-dom";
import Comments from "../comments/Comments";
// import { FixedSizeList as List } from "react-window";
// import { FacebookShareButton, TwitterShareButton } from "react-share";
import Sidebar from "../layout/Sidebar";
import Player from "../audio_players/AudioPlayer";
import * as moment from "moment";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ArticleDetail(props) {
  // const tracks = [
  //   {
  //     url: "https://audioplayer.madza.dev/Madza-Chords_of_Life.mp3",
  //     title: "Madza - Chords of Life",
  //     tags: ["house"],
  //   },
  //   {
  //     url: "https://audioplayer.madza.dev/Madza-Late_Night_Drive.mp3",
  //     title: "Madza - Late Night Drive",
  //     tags: ["dnb"],
  //   },
  //   {
  //     url: "https://audioplayer.madza.dev/Madza-Persistence.mp3",
  //     title: "Madza - Persistence",
  //     tags: ["dubstep"],
  //   },
  // ];
  const [tracks, setTracks] = useState([]);
  const [count, setCount] = useState(0);
  const { slug } = useParams();
  // const shareUrl = window.location.href;
  const [article, setArticle] = useState({});
  const [author, setAuthor] = useState({});
  const [genres, setGenres] = useState({});
  // const [chapters, setChapters] = useState([]);
  // const [articles, setArticles] = useState([]);

  const Chapter = ({ index, key, style }) => (
    <div key={index} style={style}>
      <Link
        target="_blank"
        to={{
          // pathname: `/articles/${article.slug}/chapter/${chapter.ordering + 1}/`,
          pathname: `/articles/${article.slug}/${tracks[index].slug}/`,
        }}
      >
        {tracks[index].title}
      </Link>
    </div>
  );

  useEffect(() => {
    // var slug = props.match.params.slug;
    var link = `get_audio_article_by_slug/${slug}/${process.env.REACT_APP_DOMAIN}/`;
    API.getItemsFront(link)
      .then(async (resp) => {
        var a = resp.article;
        const url = await dataCommonFunction
          .getAudioThumpUrl(a.id)
          .catch(console.error);
        setArticle({
          id: a.id,
          title: a.title,
          author: a.author,
          slug: a.slug,
          body: a.body,
          created_at: a.created_at,
          is_finished: a.is_finished,
          number_of_view: a.number_of_view,
          thumpUrl: url,
        });
        var link2 = `get_chapters_by_story_id/${a.id}/`;
        API.getItemsFront(link2)
          .then((resp1) => {
            // setChapters(resp1.chapters);
            var tracklist = [];
            resp1.articles &&
              resp1.articles.map((a, index) => {
                var i = {
                  url: a.encrypted_audio_url,
                  key: a.juice,
                  title: a.title,
                  tags: [a.slug],
                };
                tracklist.push(i);
              });
            // setArticles(resp1.articles);
            setTracks(tracklist);
          })
          .catch((error) => console.log(error));

        var link = "get_genres_by_article_id/" + a.id.toString() + "/";
        API.getItemsFront(link).then((resp2) => {
          setGenres(resp2.genres);
        });

        var link = "get_author_by_id/" + a.author.toString() + "/";
        API.getItemsFront(link).then((resp3) => {
          setAuthor(resp3.author);
        });

        // Count page view
        // let fromSS = sessionStorage.getItem("pageView");
        let articleId = sessionStorage.getItem("articleId");
        if (!articleId || a.id != articleId) {
          const timer = setTimeout(() => {
            // sessionStorage.setItem("pageView", 1);
            sessionStorage.setItem("articleId", a.id);
            var link =
              "increase_number_of_article_view/" + a.id.toString() + "/";
            API.getItemsFront(link).then((resp4) => {
              setCount(resp4.number_of_view);
            });
          }, 10000); // 10 sec

          return () => clearTimeout(timer);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <React.Fragment>
      <div className="main">
        <section className="module-small">
          <div className="container">
            <div className="row">
              <div className="col-sm-8">
                <div className="post">
                  {/* <div className="post-thumbnail">
                    <img
                      src={article.thumpUrl}
                      alt="Blog Featured Image"
                    />
                  </div> */}
                  <div className="post-header font-alt">
                    <div className="post-title-block">
                      <div className="block-90-percent-left">
                        <h1 className="post-title">{article.title}</h1>
                      </div>
                      <div
                        className="block-10-percent-right"
                        style={{ fontSize: 11 }}
                      >
                        <FontAwesomeIcon icon={faEye} />{" "}
                        {article.number_of_view}
                      </div>
                    </div>
                    <div className="post-meta post-meta-detail">
                      tác giả&nbsp;
                      <Link
                        target="_blank"
                        to={{
                          pathname: `/authors/${author.slug}/`,
                        }}
                      >
                        {author.name}
                      </Link>{" "}
                      |{" "}
                      {/* {new Intl.DateTimeFormat("en-US", {
                        month: "long",
                        day: "2-digit",
                        year: "numeric",
                      }).format(new Date.(article.created_at))} */}
                      {moment(new Date(article.created_at)).format(
                        "DD-MM-YYYY"
                      )}{" "}
                      |{" "}
                      {Array.isArray(genres) &&
                        genres.map(function (g, i) {
                          return (
                            <React.Fragment key={i}>
                              <Link
                                target="_blank"
                                to={{
                                  pathname: `/genres/${g.slug}/`,
                                  //   state: { id: props.data.id },
                                }}
                              >
                                {g.name}
                              </Link>
                              {/* <a href="#">{g.name}</a> */}
                              {i < genres.length - 1 ? " | " : ""}
                            </React.Fragment>
                          );
                        })}
                    </div>
                  </div>
                  <div className="post-entry">
                    <Player trackList={tracks} note={article.title}/>
                  </div>
                  <div className="post-entry post-body">
                    <h5 className="widget-title font-alt">
                      <strong>Giới thiệu</strong>
                    </h5>
                    <div className="margin-top-30">
                      {"Trạng thái: "}
                      {article.is_finished ? "Đã hoàn" : "Đang ra"}
                    </div>
                    <div className="margin-top-30">
                      {ReactHtmlParser(article.body)}
                    </div>
                  </div>
                  <div>
                    {/* <CommentForm /> */}
                    <Comments
                      commentsUrl="http://localhost:3004/comments"
                      currentUserId="1"
                      item_id={article.id}
                    />
                  </div>
                </div>
              </div>
              <Sidebar />
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}

import * as React from "react";

const CustomLeftArrow = ({ onClick }) => (
  <button
    onClick={() => onClick()}
    aria-label="Go to previous slide"
    className="custom-carousel__arrow custom-carousel__arrow--left "
    type="button"
  ></button>
);
const CustomRightArrow = ({ onClick }) => (
  <button
    onClick={() => onClick()}
    aria-label="Go to next slide"
    className="custom-carousel__arrow custom-carousel__arrow--right "
    type="button"
  ></button>
);

const CustomButtonGroup = ({ next, previous, goToSlide, carouselState }) => {
  const { totalItems, currentSlide } = carouselState;
  return (
    <div className="custom-button-group">
      <div>Current slide is {currentSlide}</div>
      <button onClick={() => previous()}>Previous slide</button>
      <button onClick={() => next()}>Next slide</button>
      <button
        onClick={() => goToSlide(Math.floor(Math.random() * totalItems + 1))}
      >
        Go to a random slide
      </button>
    </div>
  );
};
const CustomButtonGroupAsArrows = ({ next, previous }) => {
  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <h4>These buttons can be positioned anywhere you want on the screen</h4>
      <button onClick={previous}>Prev</button>
      <button onClick={next}>Next</button>
    </div>
  );
};

export {
  CustomLeftArrow,
  CustomRightArrow,
  CustomButtonGroup,
  CustomButtonGroupAsArrows,
};

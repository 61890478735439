import React, { useState, useEffect } from "react";
import { API } from "../common/api-service";
import { dataCommonFunction } from "../common/data-common-function";
import { Link } from "react-router-dom";
// import * as moment from "moment";
import parse from "html-react-parser";
// import { faEye } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function LastArticles(props) {
  const NEWEST_ARTICLE_QUANTITY = props.quantity;
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    dataCommonFunction
      .getNewestArticles(NEWEST_ARTICLE_QUANTITY)
      .then((resp) => {
        setArticles(resp);
      });

    // var link = `get_lastest_audio_articles_by_site_domain/${process.env.REACT_APP_DOMAIN}/${LASTEST_ARTICLE_QUANTITY}/`;
    // API.getItemsFront(link)
    //   .then(async (resp) => {
    //     var datas = resp.articles;
    //     var articlesList = [];
    //     for (let i = 0; i < datas.length; i++) {
    //       const url = await dataCommonFunction
    //         .getAudioThumpUrl(datas[i].id)
    //         .catch(console.error);
    //       var link = "get_author_by_id/" + datas[i].author.toString() + "/";
    //       var author = await API.getItemsFront(link).then((resp3) => {
    //         return resp3.author;
    //       });
    //       var article = {
    //         id: datas[i].id,
    //         title: datas[i].title,
    //         notes: datas[i].notes,
    //         author: author,
    //         slug: datas[i].slug,
    //         created_at: datas[i].created_at,
    //         is_finished: datas[i].is_finished,
    //         number_of_view: datas[i].number_of_view,
    //         thumpUrl: url,
    //       };
    //       articlesList.push(article);
    //     }
    //     setArticles(articlesList);
    //   })
    //   .catch((error) => console.log(error));
  }, []);

  return (
    <React.Fragment>
      <ul className="widget-posts">
        {articles &&
          articles.map((data, index) => (
            <li className="clearfix" key={"sidebar" + index}>
              <div className="widget-posts-image promotion-box">
                {data.is_finished ? (
                  <div className="widget-promotion-tag-box">
                    <div className="widget-promotion-tag-text">FULL</div>
                  </div>
                ) : (
                  ""
                )}
                <Link
                  target="_blank"
                  to={{
                    pathname: `/articles/${data.slug}/`,
                    // state: { id: data.article.id },
                  }}
                  className="featured-img"
                >
                  <img src={data.thumpUrl} alt="Post Thumbnail" />
                </Link>
              </div>
              <div className="widget-posts-body">
                <div className="widget-posts-title">
                  <Link
                    target="_blank"
                    to={{
                      pathname: `/articles/${data.slug}/`,
                      // state: { id: data.id },
                    }}
                  >
                    {data.title}
                  </Link>
                </div>
                <div className="widget-posts-meta">
                  <Link
                    target="_blank"
                    to={{
                      pathname: `/authors/${data.author.slug}/`,
                    }}
                  >
                    {data.author.name}
                  </Link>
                </div>
                <div className="widget-posts-meta">
                  <p style={{ margin: 0, fontSize: 11 }}>
                    {data.notes ? parse(data.notes) : ""}
                  </p>
                  {/* <p style={{ fontSize: 9 }}>
                    <FontAwesomeIcon icon={faEye} /> {data.number_of_view}
                  </p> */}
                  {/* {moment(new Date(data.created_at)).format("DD-MM-YYYY")} */}
                </div>
              </div>
            </li>
          ))}
      </ul>
    </React.Fragment>
  );
}

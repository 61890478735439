import React from "react";
import { Link } from "react-router-dom";
import * as moment from "moment";
import parse from "html-react-parser";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function BlogStandardRightSidebar(props) {
  return (
    <React.Fragment>
      {/* <div class="col-sm-8"> */}
      <div className="post">
        <div className="large-widget-posts-image promotion-box">
          {props.data.is_finished ? (
            <div className="promotion-tag-box">
              <div className="promotion-tag-text">FULL</div>
            </div>
          ) : (
            ""
          )}
          <Link
            // target="_blank"
            to={{
              pathname: `/articles/${props.data.slug}/`,
            }}
            className="featured-img"
          >
            <img src={props.data.thumpUrl} alt="Post Thumbnail" />
          </Link>
        </div>
        <div className="widget-posts-body">
          <div className="post-header font-alt">
            <h2 className="post-title">
              <Link
                // target="_blank"
                to={{
                  pathname: `/articles/${props.data.slug}/`,
                }}
              >
                {props.data.title}
              </Link>
            </h2>
            <div className="post-meta">
              Tác giả&nbsp;
              <Link
                // target="_blank"
                to={{
                  pathname: `/authors/${props.data.author.slug}/`,
                }}
              >
                {props.data.author.name}
              </Link>{" "}
              | {moment(new Date(props.data.created_at)).format("DD/MM/YYYY")} |{" "}
              {Array.isArray(props.data.genres) &&
                props.data.genres.map(function (g, i) {
                  return (
                    <React.Fragment key={i}>
                      <Link
                        // target="_blank"
                        to={{
                          pathname: `/genres/${g.slug}/`,
                          //   state: { id: props.data.id },
                        }}
                      >
                        {g.name}
                      </Link>
                      {/* <a href="#">{g.name}</a> */}
                      {i < props.data.genres.length - 1 ? " | " : ""}
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
          <div className="post-entry">
            <div className="block-65-percent-left">
              <p>{props.data.notes ? parse(props.data.notes) : ""}</p>
              {"Trạng thái: "}
              {props.data.is_finished ? "Hoàn thành" : "Đang ra"}
            </div>
            <div className="block-20-percent-right" style={{ fontSize: 11 }}>
              <FontAwesomeIcon icon={faEye} /> {props.data.number_of_view}
            </div>
          </div>
        </div>
        {/* <div class="post-header font-alt">
          <h2 class="post-title">
            <a href="#">Our trip to the Alps</a>
          </h2>
          <div class="post-meta">
            By&nbsp;<a href="#">Mark Stone</a>| 23 November | 3 Comments |{" "}
            <a href="#">Photography, </a>
            <a href="#">Web Design</a>
          </div>
        </div>
        <div class="post-entry">
          <p>
            A wonderful serenity has taken possession of my entire soul, like
            these sweet mornings of spring which I enjoy with my whole heart. I
            am alone, and feel the charm of existence in this spot, which was
            created for the bliss of souls like mine.
          </p>
        </div>
        <div class="post-more">
          <a class="more-link" href="#">
            Read more
          </a>
        </div> */}
      </div>
      {/* </div> */}
    </React.Fragment>
  );
}

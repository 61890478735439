import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function SearchBox() {
  const [searching_text, setSearchingText] = useState("");
  const navigate = useNavigate();
  // const history = useHistory();

  const handleChange = (evt) => {
    setSearchingText(evt.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      navigate("/search/", {
        search: event.target.value,
      });
    }
  };

  const onSearch = (event) => {
    navigate("/search/", {
      search: searching_text,
    });
  };

  useEffect(() => {
    setSearchingText("");
  }, []);

  return (
    <React.Fragment>
      {/* <div className="col-sm-4 col-md-3 col-md-offset-1 search-area"> */}
      <form role="form">
        <div className="search-box">
          <input
            id="search"
            name="search"
            value={searching_text}
            onChange={handleChange}
            className="form-control"
            type="text"
            placeholder="Search..."
            onKeyDown={handleKeyDown}
          />
          {/* <Link
            // target="_blank"
            to={"/search/"}
            state={{ searching_text: searching_text }}
            className="search-btn search-btn-plus"
          >
            <i className="fa fa-search"></i>
          </Link> */}
          <button className="search-btn search-btn-plus" onClick={onSearch}>
            <i className="fa fa-search"></i>
          </button>
        </div>
      </form>
      {/* </div> */}
    </React.Fragment>
  );
}

import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import AdsIcon from "./components/advertising/AdsIcon";
import Homepage from "./components/others/Homepage";
import PrivacyPolicy from "./components/others/PrivacyPolicy";
import NotFound from "./components/common/NotFound";
import ArticleDetail from "./components/articles/ArticleDetail";
import Genres from "./components/articles/Genres";
import Authors from "./components/articles/Authors";
import SearchResults from "./components/articles/SearchResults";

function App() {
  return (
    <React.Fragment>
      <Router classNameName="App">
        <MainLayout>
          <Routes>
            <Route path="/" element={<Homepage />} exact />
            <Route path="/privacy_policy/" element={<PrivacyPolicy />} exact />
            <Route path="/articles/" element={<Homepage />} exact />
            <Route path="/articles/:slug/" element={<ArticleDetail />} />
            <Route path="/genres/:slug/" element={<Genres />} />
            <Route path="/authors/:slug/" element={<Authors />} />
            <Route path="/search/" element={<SearchResults />} />
            <Route element={<NotFound />} />
          </Routes>
        </MainLayout>
      </Router>
      <AdsIcon />
    </React.Fragment>
  );
}

export default App;
